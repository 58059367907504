<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PRODUCT TO GROUP</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- PRODUCT -->
      <div>
        <vs-label text="Product" />
        <multiselect
          v-model="selected"
          :options="productChoices"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select products"
          label="label"
          track-by="id"
          :show-labels="false"
        >
          <template slot="selection" slot-scope="{ values }">
            <span v-if="values.length > 2" class="multiselect__single">
              {{ values.length }} products selected
            </span>
          </template>
        </multiselect>

        <button
          v-if="selected.length"
          class="vs-component vs-button mt-2 vs-button-danger vs-button-border"
          @click="initValues"
        >
          Clear all products
        </button>
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';

import { ProductAction, ProductGroupAction } from '@/store/actionTypes';

import moduleProduct from '@/store/products/moduleProduct';
import sidebarMixin from '@/mixins/sidebarMixin';

export default {
  components: {
    Multiselect,
  },
  mixins: [sidebarMixin],
  props: {
    productGroupId: {
      type: Number,
      required: true,
    },
    addedGroupProducts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      this.initValues();
      this.$validator.reset();
    },
  },
  computed: {
    ...mapState('product', {
      products: ({ products }) => products,
    }),

    isFormValid() {
      return !this.errors.any() && this.selected.length;
    },
    productChoices() {
      const choices = this.products
        .filter(({ id }) => !this.addedGroupProducts.includes(id))
        .map(({ id, name, brandName, categoryName }) => {
          const details = categoryName
            ? `[${categoryName} - ${brandName}]`
            : `[${brandName}]`;

          return { id, label: `${name} ${details}` };
        });

      return choices;
    },
  },
  methods: {
    ...mapActions('product-group', [ProductGroupAction.addProduct]),
    ...mapActions('product', [ProductAction.fetch]),

    registerStoreModules() {
      if (!moduleProduct.isRegistered) {
        this.$store.registerModule('product', moduleProduct);
        moduleProduct.isRegistered = true;
      }
    },
    initValues() {
      this.selected = [];
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.$vs.loading();

      try {
        await Promise.all(
          this.selected.map(({ id }) =>
            this.addProduct({
              product_group_id: this.productGroupId,
              product_id: id,
            })
          )
        );
        this.$emit('refreshList');

        this.notifySuccess(
          'Products added to group',
          'Products have been successfully added to group.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with adding products to group.');
      }

      this.$emit('close');
      this.initValues();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchProducts();
    } catch (error) {
      this.notifyError(error, 'Problem with loading products.');
    }

    this.$vs.loading.close();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
