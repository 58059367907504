<template>
  <data-list-view-wrapper>
    <h3 v-if="productGroupName" class="mb-6">
      Product group: {{ productGroupName }}
    </h3>

    <product-group-sidebar
      v-if="$gate.can('create_product_groups')"
      :active="addNewDataSidebar"
      :product-group-id="productGroupId"
      :added-group-products="addedGroupProducts"
      @close="toggleDataSidebar"
      @refreshList="refreshList"
    />

    <vx-table
      ref="table"
      v-model="selected"
      multiple
      pagination
      :max-items="itemsPerPage"
      search
      :data="groupProducts"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <action-dropdown>
            <action-dropdown-item
              v-if="$gate.can('delete_product_groups')"
              icon="TrashIcon"
              title="Delete"
              @click="deleteSelected"
            />
          </action-dropdown>

          <button-create
            v-if="$gate.can('create_product_groups')"
            title="Add product to group"
            @click="addNewData"
          />
        </div>

        <items-per-page
          :page="currentPage"
          :per-page="itemsPerPage"
          :total="groupProducts.length"
          :queried-items="queriedItems"
          @change="changeItemsPerPage"
        />
      </div>

      <template slot="thead">
        <vs-th>Image</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="brandName">Brand</vs-th>
        <vs-th sort-key="categoryName">Category</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
            <vs-td class="img-container">
              <img :src="tr.pictureUrl" class="product-img" />
            </vs-td>

            <vs-td>
              <p class="product-name">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-brand">{{ tr.brandName }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.categoryName }}</p>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                v-if="$gate.can('delete_product_groups')"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.name)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions } from 'vuex';

import { ProductGroupAction } from '@/store/actionTypes';

import confirmationMixin from '@/mixins/confirmationMixin';
import dataListMixin from '@/mixins/dataListMixin';
import moduleProductGroup from '@/store/product-groups/moduleProductGroup';

import ActionDropdown from '@/views/components/actions/ActionDropdown';
import ActionDropdownItem from '@/views/components/actions/ActionDropdownItem';
import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import ItemsPerPage from '@/views/components/ItemsPerPage';
import ProductGroupSidebar from '@/views/sidebars/ProductGroupSidebar';
import VxTable from '@/components/vx-table/vxTable';

export default {
  components: {
    ActionDropdown,
    ActionDropdownItem,
    ButtonCreate,
    DataListViewWrapper,
    ItemsPerPage,
    ProductGroupSidebar,
    VxTable,
  },
  mixins: [confirmationMixin, dataListMixin],
  data() {
    return {
      groupProducts: [],
      productGroup: {},
    };
  },
  computed: {
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.groupProducts.length;
    },
    productGroupId() {
      return +this.$route.params.id;
    },
    productGroupName() {
      return this.productGroup.name;
    },
    addedGroupProducts() {
      return this.groupProducts.map((product) => product.id);
    },
  },
  methods: {
    ...mapActions('product-group', [
      ProductGroupAction.fetchByGroup,
      ProductGroupAction.fetchOne,
      ProductGroupAction.removeFromGroup,
    ]),

    registerStoreModules() {
      if (!moduleProductGroup.isRegistered) {
        this.$store.registerModule('product-group', moduleProductGroup);
        moduleProductGroup.isRegistered = true;
      }
    },
    async refreshList() {
      this.groupProducts = await this.fetchByGroup(this.productGroupId);
      this.$vs.loading.close();
    },
    addNewData() {
      if (!this.$gate.can('create_product_groups')) {
        return;
      }

      this.toggleDataSidebar(true);
    },
    updateSelected(productId) {
      const updatedSelectedList = this.selected.filter(({ id }) => {
        return id !== productId;
      });
      this.selected = updatedSelectedList;
    },
    async deleteData(productId) {
      if (!this.$gate.can('delete_product_groups')) {
        return;
      }
      this.$vs.loading();

      try {
        await this.removeFromGroup({
          groupId: +this.productGroupId,
          productId: +productId,
        });

        const groupProducts = this.groupProducts.filter(
          ({ id }) => id !== productId
        );
        this.groupProducts = groupProducts;

        this.updateSelected(productId);

        this.notifySuccess(
          'Product removed from group',
          'The product has been successfully removed from group.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with removing product from group.');
      }

      this.$vs.loading.close();
    },
    deleteSelected() {
      const products = this.selected.map(({ id }) => {
        return {
          groupId: +this.productGroupId,
          productId: +id,
        };
      });

      if (products.length) {
        this.confirmDeleteAll(products);
      }
    },
    confirmDeleteAll(products) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove',
        text: `Are you sure you want to remove ${products.length} selected products from group?`,
        accept: () => this.deleteAll(products),
        acceptText: 'Remove',
      });
    },
    async deleteAll(products) {
      this.$vs.loading();

      try {
        await Promise.all(
          products.map((product) => this.removeFromGroup(product))
        );

        const removedProductIds = products.map(({ productId }) => productId);
        const groupProducts = this.groupProducts.filter(
          ({ id }) => !removedProductIds.includes(id)
        );

        this.groupProducts = groupProducts;

        this.notifySuccess(
          'Selected products deleted',
          'Selected products has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting products.');
      }

      this.$vs.loading.close();
      this.selected = [];
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      this.groupProducts = await this.fetchByGroup(this.productGroupId);
      this.productGroup = await this.fetchOne(this.productGroupId);
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>
